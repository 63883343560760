//
// component: hero
//

.hero-background{
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: 100vh;
    .hero-image, .hero-mask{
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
    }
    .hero-image{
        background-color: $primary;
        background-size: cover;
        background-position: 50%;
        background-blend-mode: soft-light;
    }
    .hero-mask{
        background: linear-gradient(to bottom, transparent 0%,transparent 60%, $primary 100%);
    }
}


@include media-breakpoint-down(md) {
    .hero-background{
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 50%;
        .hero-mask{
            background: linear-gradient(to bottom, transparent 0%,transparent 80%, $primary 100%);
        }
    }
}

@include media-breakpoint-down(md) {
    .hero-background{
        height: 60vh;
    }
}

@include media-breakpoint-down(sm) {
    .hero-background{
        height: 40vh;
    }
}