
ul.shop-items-list{
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    margin: 60px -10px;
    padding-bottom: 140px;
    > li{
        flex: 0 0 33.333%;
        padding: 10px;
        cursor: pointer;
    }

    .shop-item{
        border: 1px solid #3d4142;
        text-align: center;
        transition: all 0.3s;
    }

    > li:hover .shop-item{
        border-color: $secoundary;
    }
    .shop-item-title{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 25px;
        p{
            margin-bottom: 10px;
        }
    }

    .shop-item-logo{
        padding: 20px;
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        img{
            max-width: 195px;
        }
    }

    .shop-item-image{
        position: relative;
        overflow: hidden;
        padding-bottom: 52.5%;
        background-size: cover;
        background-position: 50%;
    }

    .shop-item-description{
        display: none;
    }
}

@include media-breakpoint-down(xl) {
    ul.shop-items-list{
        padding-bottom: 40px;
    }
}

@include media-breakpoint-down(lg) {
    ul.shop-items-list{
        >li{
            flex: 0 0 50%;
        }
    }
}

@include media-breakpoint-down(sm) {
    ul.shop-items-list{
        >li{
            flex: 0 0 100%;
        }
    }
}