//
// component: swiper
//

.swiper-pagination-bullets{
    text-align: center;
    margin: 40px 0;
}

.swiper-pagination-bullet {
    outline: none;
    display: inline-block;
    width: 10px;
    height: 10px;
    border: 2px solid transparent;
    border-radius: 50%;
    background-color: $primary;
    opacity: 1;
    margin: 0 6px;
}

.swiper-pagination-bullet-active {
    border: 2px solid $secoundary;
    transform: scale(1.4);
    background-color: transparent;
}

.swiper-button-disabled{
    opacity: 0.4;
}