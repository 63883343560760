// 
// layout: footer
//

.header{
    position: absolute;
    z-index: 999;
    left: 0;
    right: 0;
    top: 0;
    background-color: transparent;
    transition: all 0.3s;
    
    .header-row{
        display: flex;
        align-items: center;
        padding: 20px 50px;
    }
    .header-brand{
        padding: 5px 0;
        margin-right: auto;
        img{
            max-width: 120px;
            display: block;
        }
    }

    ul.header-menu{
        display: flex;
        margin: 0;
        padding: 0;
        li{
            margin: 0 25px;
        }
        li a{
            color: $white;
            font-family: 'Visby CF';
            font-size: 15px;
            display: block;
            padding: 16px 0px;
            position: relative;
            &:hover{
                color: $secoundary;
            }
        }
        li a.active{
            color: $secoundary;
            &:before{
                content: "";
                position: absolute;;
                left: 0px;
                right: 0;
                bottom: 0;
                height: 1px;
                background-color: $secoundary;
            }
        }
    }

    .header-phone{
        position: relative;
        margin: 0 60px;
        a {
            color: $white;
            font-size: 18px;
            font-weight: 400;
            &:hover{
                color: $secoundary;
            }
        }

        .phone-gradient{
            position: absolute;
            top: -95px;
            left: 0;
            right: 0;
            height: 9px;
            background: repeating-linear-gradient( 90deg,#b28774, #b28774 1px,transparent 2px,transparent 4px);
        }
    }

    ul.header-lang{
        margin: 0;
        padding: 0;
        margin: 0 2px;
        li a{
            width: 50px;
            height: 60px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: $white;
            font-size: 15px;
            font-family: 'Visby CF';
            text-transform: uppercase;
            border:1px solid $secoundary;
            background-color: transparent;
            transition: all 0.3s;

            &:hover{
                background-color: $secoundary;
            }
        }
    }

    .header-toggler{
        display: none;
    }
}

@include media-breakpoint-down(xl) {
    
    .header{
        .header-row{
            padding: 10px 30px;
        }
        
        .header-brand{
            img{
                max-width: 120px;
            }
        }
        ul.header-menu li{
            margin: 0 15px;
        }
        .header-phone{
            margin: 0 20px;
            .phone-gradient{
                top: -58px;
            }
        }
    }
}


@include media-breakpoint-down(lg) {
    .header{
        position: fixed;
        background-color: #101415;
        ul.header-menu, .header-phone, ul.header-lang, .header-phone .phone-gradient{
            display: none;
        }

        .header-brand{
            img{
                max-width: 70px;
            }
        }

        .header-row{
            padding: 10px 15px;
        }

        .header-toggler{
            display: block;
        }
    }
}

@include media-breakpoint-down(md) {}

@include media-breakpoint-down(sm) {}



