
$white: #fff;
$white-text: rgba(255, 255, 255, 0.9);

$primary:    #101415;
$secoundary: #b18673;
$dark:       #101415;

$text-color: rgba(255, 255, 255, 0.4);

$gray-300:   #282b2c;










