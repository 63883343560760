// 
// Buttons
// 
.btn:hover,
.btn:focus,
.btn:active {
    box-shadow: none !important;
}

button {
    cursor: pointer;
}

.btn {
    min-width: 218px;
    position: relative;
    font-size: 14px;
    font-weight: 400;
    padding: 12px 20px;
    color: $white;
    border:0px;
    outline: 0px;
    box-shadow: 0px;
    display: inline-flex;
    align-items: center;
    justify-content: flex-end;
    background-color: #6c4536;
    border-radius: 0px;
    text-align: center;
    overflow: hidden;
    z-index: 20;
    
    &:before{
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        max-width: 0%;
        background-color: $secoundary;
        transition: all .4s cubic-bezier(.365,.84,.44,1);
        z-index: -1;
        
    }
    &:active:not(:disabled):not(.disabled),
    &:hover:not(:disabled):not(.disabled),
    &:focus:not(:disabled):not(.disabled),
    &:active:not(:disabled):not(.disabled) {
        outline: none;
        color: $white;
        &:before{
            max-width: 100%;
        }
    }
}

.btn-white{
    background-color: transparent;
    border: 1px solid $white;
    padding: 16px 20px;
    min-width: 180px;
    &:before{
        background-color: #6c4536;
        width: 10%;
        max-width: 10%;
    }
}

.btn-white:hover:before{
    width: 100%;
    max-width: 100%;
}

.link-more{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: relative;
    .line{
        width: 180px;
        max-width: 70%;
        height: 1px;
        background-color: rgba($white, 0.2);
        position: relative;
        &:before{
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 0%;
            height: 100%;
            background-color: $secoundary;
            transition: all 0.6s;
        }
    }
    .text{
        margin-left: 20px;
        font-size: 14px;
        font-family: 'Visby CF';
    }
}

.link-more:hover{
    .line:before{
        width: 100%;
    }
}

@include media-breakpoint-down(xl) {}
