// 
// page: blog
// 



.blog-item{
    position: relative;
    overflow: hidden;
    .blog-meta-container{
        text-align: center;
    }
    .blog-meta{
        display: inline-block;
        font-weight: 300;
        color: $white;
        border: 1px solid $secoundary;
        border-bottom: 0px;
        padding: 26px 20px;
    }
    .blog-title{
        padding: 45px 20px 20px 45px;
    }

    .blog-item-image{
        display: block;
    }

    .image-holder{
        padding-bottom: 80%;
    }
}

.blog-item:hover{
    
}

ul.blog-list{
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;

    > li{
        padding-bottom: 5%;
        flex: 0 0 34%;
        max-width: 34%;
    }

    > li:nth-child(4n+1){
        padding-top: 10%;
        margin-left: 12%;
        .blog-item{
            .image-holder{
                padding-bottom: 60%;
            }
        }
    }
    
    > li:nth-child(4n+2){
        margin-left: 12%;
        .blog-item{
            .image-holder{
                padding-bottom: 110%;
            }
        }
    }

    > li:nth-child(4n+3){
        .blog-item{
            .image-holder{
                padding-bottom: 110%;
            }
        }
    }

    > li:nth-child(4n+4){
        padding-top: 10%;
        margin-left: 10%;
        .blog-item{
            .image-holder{
                padding-bottom: 60%;
            }
        }
    }
}

.blog-pager{
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
    padding-right: 22%;
    a{
        color: $white-text;
        margin: 0 15px;
        &:hover{
            color: $secoundary;
        }
    }
}

.blog-single{
    .blog-pager{
        padding-right: 60px;
    }
    .blog-meta{
        padding: 24px 12px;
        color: $white;
        border:1px solid $secoundary;
    }
    .blog-single-head{
        margin: 25px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .blog-single-content{
        padding: 40px 25%;
    }
}



@include media-breakpoint-down(xl) {
    .blog-single .blog-single-content {
        padding: 40px 20%;
    }
}

@include media-breakpoint-down(lg) {
    .blog-item {
        .blog-title{
            padding: 20px 20px 20px 25px;
            .h4, .h4{
                font-size: 18px;
            }
        }
    }

    .blog-single{
        .blog-single-content{
            padding: 40px 10%;
        }
    }
}
@include media-breakpoint-down(md) {

    .blog-single{
        .blog-pager{
            padding-right: 20px;
        }
        .blog-single-content {
            padding: 20px 40px;
        }
    }
}
@include media-breakpoint-down(sm) {
    ul.blog-list{
        > li{
            padding-bottom: 5%;
            flex: 0 0 100%;
            max-width: 100%;
        }
    
        > li:nth-child(4n+1), > li:nth-child(4n+2), > li:nth-child(4n+3), > li:nth-child(4n+4){
            padding: 0;
            margin: 0;
            .blog-item{
                .image-holder{
                    padding-bottom: 60%;
                }
            }
        }
    }

    .blog-item{
        .blog-title{
            padding: 40px 20px;
        }
    }

    .blog-single{
        .blog-single-head{
            margin: 10px 0;
            padding: 5px 20px;
        }
        .blog-pager{
            padding-right: 20px;
        }
        .blog-single-content {
            padding: 20px 10px;
        }
    }
}
