
.section{
    position: relative;
    overflow: hidden;
}

.section-hero{
    padding-top: 30vh;
    padding-bottom: 30vh;
}

.section-relative{
    position: relative;
}

.section-hero-sm{
    padding-bottom: 12vh;
}


@keyframes anim-bounce-sm {
    from {
        left: -5px;
    }
    to {
        left: -12px;
    }
}

.scroll{
    position: absolute;
    top: 100vh;
    right: 0;
    z-index: 44;
    color: $white;
    .scroll-transform{
        transform: rotate(-90deg);
        position: absolute;
        right: 40px;
        top: -140px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .scroll-icon, .scroll-text{
        display: inline-block;
    }
    .scroll-icon{
        font-size: 12px;
        position: relative;
        left: -5px;
        top: 2px;
        animation: anim-bounce-sm 0.85s ease-in-out infinite alternate;
    }
    .scroll-text{
        text-transform: uppercase;
        font-size: 12px;
        margin-left: 12px;
    }
}

.hero-title{
    font-size: 200px;
    font-weight: 900;
    background: linear-gradient(to right, #6c4536 0%, #bc9d8f 20%, #6c4536 47%, #b78c79 68%, #6c4536 100%);
    line-height: 1;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    span{
        display: block;
    }
}

.hero-title-news{
    line-height: 1.2;
}

.section-content{
    padding: 40px;
    .h4{
        margin-bottom: 40px;
    }
    p{
        margin-bottom: 30px;
    }

    p:first-of-type{
        position: relative;
        &:before{
            content: "";
            position: absolute;
            left: -60px;
            top: 10px;
            height: 84px;
            width: 1px;
            background-color: $secoundary;
        }
    }
}

.section-head{
    padding-top: 65px;
    .section-description{
        padding: 65px 10px 10px 100px;
        font-family: 'Roboto Slab', serif;
        font-weight: 300;
    }
}

.head-description-only{
    padding-top: 0;
    .section-description{
        padding-top: 45px;
    }
}

.section-bg{
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: 100vh;

    .bg-image, .bg-mask-top, .bg-mask-bottom{
        position: absolute;
        left: 0;
        right: 0;
        top: 20%;
        bottom: -20%;
    }

    .bg-image{
        background-color: $primary;
        background-size: cover;
        background-position: 50%;
        background-blend-mode: soft-light;
        opacity: 0.75;
    }
    .bg-mask-top{
        background: linear-gradient(to bottom, $primary 0%, transparent 80%, transparent 100%);
    }
    .bg-mask-bottom{
        background: linear-gradient(to bottom, transparent 0%,transparent 80%, $primary 100%);
    }
}

body{
    position: relative;
}

.page-bg-bottom{
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 70vw;

    .bg-image, .bg-mask-top, .bg-mask-bottom{
        position: absolute;
        left: 0;
        right: 0;
        top: 0%;
        bottom: 0%;
    }

    .bg-image{
        background-color: $primary;
        background-size: 100%;
        background-repeat: no-repeat;
        background-blend-mode: soft-light;
        opacity: 0.45;
    }
    .bg-mask-top{
        background: linear-gradient(to bottom, $primary 0%, transparent 35%, transparent 100%);
    }
    .bg-mask-bottom{
        background: linear-gradient(to bottom, transparent 0%,transparent 25%, $primary 100%);
    }
}


.section-swiper{
    position: relative;
    transform: translateX(-16.666%);
    .section-swiper-nav{
        position: absolute;
        z-index: 55;
        top: 50%;
        transform: translateY(-50%);
        right: -12%;
        display: flex;
        align-items: center;

        a{
            color: $white-text;
            margin: 0 15px;
            &:hover{
                color: $secoundary;
            }
        }
    }
}

.image-block{
    img{
        display: block;
        width: 100%;
    }
}

.image-block-pull-left{
    margin-left: -30px;
}


.section-box{
    position: relative;
    z-index: 10;
    background-color: $white;
    display: flex;
    .box-image{
       flex: 0 0 30%;
    }

    .h1, .h2, .h3, .h4, .h5, .h6, p{
        color: $primary;
    }


    a{
        color: $dark;
        &:hover{
            color: $secoundary;
        }
    }

    .box-body{
        flex: 0 0 70%;
        padding: 160px 20px;
        text-align: center;
        .inner{
            display: inline-block;
            max-width: 260px;
            text-align: left;
        }
    }

    .box-close{
        position: absolute;
        left: 30px;
        top: 30px;
        color: rgba(0 ,0 ,0 ,0.65);
        cursor: pointer;
        &:hover{
            color: $secoundary;
        }
    }

    .box-head{
        margin-bottom: 35px;
    }

    .phone{
        color: $secoundary;
        font-family: 'Roboto Slab', serif;
    }

    .phone-group{
        margin: 40px 0;
        p{
            margin: 0;
            font-size: 16px;
            font-weight: 300;
        }
    }

    .content-border{
        border-top: 1px solid $secoundary;
        padding-top: 20px;
        margin-top: 40px;
    }

    .hours{
        margin-bottom: 20px;
        p{
            margin: 0;
        }
    }

    .shop-image{
        display: none;
    }
}


@include media-breakpoint-down(xl) {
    .hero-title{
        font-size: 120px;
    }
    .section-hero{
        padding-top: 35vh;
        padding-bottom: 25vh;
    }

    .section-box{
        .box-body{
            padding: 90px 20px;
        }
        .box-close{
            left: 20px;
            top: 20px;
        }
    }

    .head-description-only{
        .section-description{
            padding-left: 10px;
        }
    }

    .section-content p:first-of-type:before{
        left: -30px;
    }
}

@include media-breakpoint-down(lg) {
    .scroll{
        display: none;
    }
    .section-head{
        .section-description {
            padding: 30px 10px 10px 60px;
        }
    }

    .head-description-only{
        .section-description{
            padding: 30px 10px 10px 0px;
        }
    }

    .section-swiper .section-swiper-nav{
        right: 0;
        transform: translate(110%, -50%);
    }
}

@include media-breakpoint-down(md) {
    .hero-title{
        padding-left: 40px;
    }
    .image-block-pull-left{
        margin-left: 0;
    }
    .section-hero{
        padding-top: 240px;
        padding-bottom: 100px;
    }
    .section-head{
        padding: 40px 0px 0px 40px;
        .section-description{
            padding: 20px 0;
        }
    }

    .head-description-only{
        .section-description{
            padding: 0px;
        }
    }

    .section-swiper{
        transform: translateX(0%);
        padding-bottom: 60px;
        .section-swiper-nav{
            top: auto;
            bottom: 20px;
            left: 0;
            right: 0;
            transform: translate(0%, 0%);
            justify-content: center;
        }
    }

    .row-images{
        img{
            margin-bottom: 20px;
        }
    }
}


@include media-breakpoint-down(sm) {
    .hero-title{
        padding-left: 15px;
        font-size: 52px;
    }

    .section-hero{
        padding-top: 100px;
        padding-bottom: 10px;
    }
    
    .section-content{
        padding: 15px;
    }
    .section-head{
        padding: 25px 20px 0px 15px;
        .section-description{
            padding: 15px 0;
        }
    }
    
    .section-box{
        .box-body{
            padding: 40px 10px;

            .h3{
                font-size: 20px;
            }
        }
        .phone-group{
            margin: 20px 0;
        }
    }
}



