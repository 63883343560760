// 
// layout: offcanvas
//
.offcanvas {
    position: fixed;
    left: 0;
    right: 0px;
    top: 0px;
    bottom: 0px;
    background-color: $primary;
    transform: translate3d(0%, -100%, 0);
    z-index: 99;
    transition: all .4s;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 120px 20px 20px 0px;
    overflow: hidden;
    
    ul.offcanvas-menu{
        opacity: 0;
        transform: translateX(50px);
        transition: all 0.3s 0.4s;
        position: relative;
        z-index: 44;
        padding: 0;
        margin: 0;
        > li a{
            font-family: 'Visby CF';
            font-size: 48px;
            font-weight: 300;
            color: $white;
            &:hover{
                color: $secoundary;
            }
        }
    }

    .offcanvas-data{
        text-align: right;
        opacity: 0;
        transition: all 0.3s 0.65s;
        transform: translateX(50px);
        .phone{
            font-family: 'Visby CF';
            color: $white;
            font-size: 16px;
            font-weight: 300;
        }
        .email{
            color: $white;
        }
    }

    ul.offcanvas-lang{
        opacity: 0;
        transition: all 0.3s 0.65s;
        transform: translateX(50px);
    }
}

.offcanvas.active{
    transform: translate3d(0, 0, 0);
    ul.offcanvas-menu{
        opacity: 1;
        transform: translateX(0px);
    }
    .offcanvas-data{
        transform: translateX(0px);
        opacity: 1;
    }
    ul.offcanvas-lang{
        transform: translateX(0px);
        opacity: 1;
    }
}


@include media-breakpoint-down(xl) {}

@include media-breakpoint-down(lg) {
    .offcanvas{
        padding-top: 90px;
    }
}

@include media-breakpoint-down(md) {
    .offcanvas{
        padding: 20px;
        padding-top: 90px;
    }
}


@include media-breakpoint-down(sm) {
    .offcanvas{
        ul.offcanvas-menu > li a{
            font-size: 32px;
        }

        .offcanvas-data{
            padding: 15px 0;
            text-align: left;
            a{
                display: block;
            }
        }
    }
}



