// 
// layout: page
//

body{
    overflow-x: hidden;
}

.page{
    overflow: hidden;
}

.page-content{
    position: relative;
    z-index: 2;
}

@include media-breakpoint-down(xl) { }

@include media-breakpoint-down(lg) { }

@include media-breakpoint-down(md) { }

@include media-breakpoint-down(sm) { }






