// 
// Google map
// 
.g-map {
    position: relative;
    overflow: hidden;
    padding-bottom: 62%;
    .g-map-canvas {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-color: $gray-100;
    }
}


.g-map-container {
    position: relative;
    padding-top: 240px;
}

.g-map-data{
    position: absolute;
    z-index: 9;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 20px 0;
    background-color: rgba(#101415, 0.8);
}

.g-map-data-list{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    li{
        padding: 20px 40px;
    }

    .h6{
        color: $white;
    }
    p{
        margin: 0;
    }
}

.g-map-data-lg{
    display: none;
}


@include media-breakpoint-down(xl) {
    .g-map-container {
        .g-map-data{
           padding: 10px 0;
        }

        .g-map-data-list li{
            padding: 20px;
        }
    }
}

@include media-breakpoint-down(lg) {
    .g-map-container {
        padding-top: 0;
        .g-map-data{
            display: none;
        }
    }

    .g-map-data-lg{
        display: block;
    }

    .g-map-data-list{
        display: flex;
        flex-wrap: wrap;
        li{
            flex: 0 0 50%;
        }
    }
}

@include media-breakpoint-down(sm) {
    .g-map {
        padding-bottom: 120%;
    }

    .g-map-data-list{
        li{
            flex: 0 0 100%;
            padding: 20px;
        }
    }
}