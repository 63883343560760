// 
// layout: footer
//

.footer{
    margin: 100px 0 40px 0px;
    position: relative;
    .footer-bg{
        position: absolute;
        z-index: 1;
        left: 0;
        right: 0;
        bottom: 0px;
        height: 100vh;
    }

    .bg-image, .bg-mask-top, .bg-mask-bottom{
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
    }

    .bg-image{
        background-color: $primary;
        background-size: cover;
        background-position: 50%;
        background-blend-mode: soft-light;
        opacity: 0.6;
    }
    .bg-mask-top{
        background: linear-gradient(to bottom, $primary 0%, transparent 40%, transparent 100%);
    }
    .bg-mask-bottom{
        background: linear-gradient(to bottom, transparent 0%,transparent 40%, $primary 100%);
    }


    .footer-wrapper{
        position: relative;
        z-index: 2;
        background-color: $primary;
        border:1px solid #282b2c;
        padding: 50px 8.33333% 40px 8.33333%;
    }

    .footer-top{
        display: flex;
        justify-content: space-between;
    }

    .footer-t-left{
        display: flex;
        align-items: center;
    }

    .footer-brand{
        margin-right: 40px;
        img{
            max-width: 122px;
        }
    }

    .footer-copyright{
        padding-top: 12px;
        p, a{
            color: rgba($white, 0.4);
        }
        a{
            margin-left: 20px;
            &:hover{
                color: $secoundary;
            }
        }
    }

    .footer-t-right{
        text-align: right;
        .h3{
            padding-top: 40px;
            font-size: 48px;
            font-family: 'Visby CF';
            color: $secoundary;
            font-weight: 100;
        }
    }

    .footer-data{
        display: flex;
        justify-content: flex-end;
        position: relative;
        padding-bottom: 40px;
        margin-bottom: 40px;
        &:after{
            content: "";
            position: absolute;
            right: 0;
            bottom: 0;
            width: 200px;
            height: 1px;
            background-color: $secoundary;
        }
        p, a{
            color: rgba($white, 0.4);
        }
        p{
            margin-left: 20px;
        }
        a{
            &:hover{
                color: $secoundary;
            }
        }
    }

    ul.footer-menu{
        margin: 0;
        padding: 0;
        display: flex;
        justify-content: flex-end;
        li{
            margin-left: 20px;
        }
        li a{
            font-size: 14px;
            color: rgba($white, 0.7);
            &:hover{
                color: $secoundary;
            }
        }
    }
}


@include media-breakpoint-down(xl) {
    .footer{
        .footer-wrapper{
           padding: 10px 8.33333% 20px 8.33333%;
        }
    }
}

@include media-breakpoint-down(lg) {
    .footer{
        .footer-wrapper {
            padding: 10px 4% 20px 4%;
        }
        .footer-t-right .h3 {
            padding-top: 30px;
            font-size: 24px;
        }

        .footer-copyright a{
            margin-left: 0;
            display: block;
        }
    }
}


@include media-breakpoint-down(md) {
    .footer{
        .footer-wrapper{
            padding: 40px 20px;
        }
        .footer-top{
            display: block;;
        }
    }
}


@include media-breakpoint-down(sm) {
    .footer{
        margin: 20px 0;
        text-align: center;
        .footer-top, .footer-t-left, .footer-t-right{
            display: block;
            text-align: center;
        }

        .footer-brand{
            margin-right: 0;
        }

        .footer-data{
            &:after{
                width: 100%;
            }
            display: block;
            p{
                margin: 5px 0;
            }
        }
    
        ul.footer-menu{
            display: block;
            li{
                margin: 5px 0;
            }
        }
    }
}
