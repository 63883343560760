// 
// layout: article
// 
article{
    h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6{
        margin-bottom: 30px;
    }
}

@include media-breakpoint-down(xl) {}
@include media-breakpoint-down(lg) {}
@include media-breakpoint-down(md) {}