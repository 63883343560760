// 
// page: home
// 

.page-home{
    padding-top: 150px;
}

// swiper main
.swiper-main-container{
    position: relative;

    .swiper-main-nav{
        position: absolute;
        z-index: 20;
        right: 0;
        top: 40px;
        padding-right: 60px;
        a{
            color: rgba(255, 255, 255, 0.9);
            margin: 0 15px;
            &:hover{
                color: $secoundary;
            }
        }
    }
}

.swiper-slider-mian{
    padding-bottom: 60px;
    .slide-image{
        position: relative;
        width: 80%;
        overflow: hidden;
        padding-bottom: 40%;
        background-size: cover;
        background-position: 50%;

        video{
            position: absolute;
            top: 50%;
            left: 50%;
            z-index: 1;
            min-width: 100%;
            min-height: 100%;
            width: auto;
            height: auto;
            transform: translate(-50%, -50%);
        }
    }

    .h2{
        position: absolute;
        z-index: 10;
        right: 0%;
        top: 45px;
        padding-right: 40px;
        font-size: 200px;
        font-weight: 900;
        background: linear-gradient(to right, #6c4536 0%, #bc9d8f 20%, #6c4536 47%, #b78c79 68%, #6c4536 100%);
        line-height: 1;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    
        span{
            display: block;
        }
    }

    .h2, .h6{
        box-sizing: border-box;
        z-index: 10;
    }

    .h6{
        position: absolute;
        right: 0%;
        bottom: 220px;
        width: 20%;
        padding: 0px 40px;
        color: $white;
        font-family: 'Roboto Slab', serif;
        font-weight: 300;
    }
    .slide-btn{
        position: absolute;
        z-index: 10;
        left: 80%;
        bottom: 60px;
        transform: translate(-50%, 50%);
    }
}


.home-page-map{
    position: relative;
    z-index: 10;
    padding: 0;
}


// section-news

.news-section{
    position: relative;
    .section-bg{
        top: -15%;

        .bg-mask-bottom{
            background: linear-gradient(to bottom, transparent 0%,transparent 60%, $primary 100%);
        }
    }
}
.section-news{
    .section-news-text{
        .section-head{
            margin-top: 60px;
        }
    }
    .section-head{
        margin-bottom: 60px;
    }

    .section-swiper-nav{
        padding-left: 55%;
        a{
            color: rgba(255, 255, 255, 0.9);
            margin: 0 15px;
            &:hover{
                color: $secoundary;
            }
        }
    }
    position: relative;
    .section-news-container{
        display: flex;
        overflow: hidden;
        .news-conatainer-inner{
            width: 120%;
            max-width: 120%;
            display: block;
        }
    }
    .section-box{
        flex: 0 0 40%;
        min-width: 0;
        .box-body{
            padding: 170px 20px 180px 20px;
        }

        .link-more{
            margin-top: 50px;
            .line{
                background-color: rgba(0, 0, 0 ,0.2);
            }
        }
    }
    .swiper-news-container{
        flex: 0 0 60%;
        width: 60%;
        min-width: 0;
    }
    .post-home{
        padding: 0 40px;
        .h4{
            font-size: 18px;
        }
        .blog-item .blog-title{
            padding: 30px 120px 20px 25px;
            opacity: 0.6;
            transition: all 0.3s;
        }
    }
    .post-type-1, .post-type-3{
        padding-top: 20%;
    }
    .post-type-2{
        .blog-item .image-holder{
            padding-bottom: 120%;
        }
    }

    .post-home:hover{
        .blog-item .blog-title{
            opacity: 1;
        }
    }

    .post-home{
        .blog-item .blog-meta{
            transition: all 0.25s 0.25s;
            transform: translateY(100%);
        }
        .blog-item .blog-title{
            opacity: 0.3;
        }
    }
    

    .swiper-slide-active, .swiper-slide-next{
        .post-home{
            .blog-item .blog-meta{
                transform: translateY(0%);
            }
            .blog-item .blog-title{
                opacity: 1;
            }
        }
    }
}


// home page content
.section-home-content{
    .row{
        margin-top: 50px;
        margin-bottom: 50px;
    }
}
.content-text{
    padding: 40px;
    .num{
        display: inline-block;
        position: relative;
        font-size: 48px;
        font-weight: 100;
        color: $secoundary;
        padding-top: 130px;
        padding-bottom: 20px;
        margin-top: 20px;
        font-family: 'Visby CF';
        &::before{
            content: "";
            position: absolute;
            left: 49%;
            top: 0;
            height: 90px;
            width: 1px;
            background-color: $secoundary;
        }
    }
    .h4{
        margin-bottom: 30px;
    }
    .link-more{
        margin-top: 50px;
    }
}

// home history
.history-row-1{
    .content-text{
        padding-bottom: 60px;
    }
}
.history-row-2{
    position: relative;
    z-index: 44;
    transform: translateY(-60px);
}

.content-text-date{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .h4{
        font-size: 48px;
        font-family: 'Visby CF';
        font-weight: 100;
        color :$secoundary;
        margin: 0;
        line-height: 1;
        letter-spacing: 2px;
    }
    p{
        color: $secoundary;
        font-size: 14px;
        font-family: 'Visby CF';
    }
}

@include media-breakpoint-down(xl) {

    .page-home{
        padding-top: 130px;
    }
    
    // swiper
    .swiper-slider-mian{
        .h2{
            font-size: 110px;
        }
        .h6{
            font-size: 16px;
            bottom: 120px;
        }
        .slide-image{
            padding-bottom: 35%;
        }
    }
    .swiper-main-container .swiper-main-nav{
        padding-right: 0;
    }

    .page-home{
        .scroll{
            top: 117vh;
        }
    }

    // section news
    .news-section .section-bg{
        top: 0%;
    }
    .section-news{
        .section-box .box-body{
            padding: 120px 20px 130px 20px;
        }
        .post-home .blog-item .blog-title{
            padding: 30px 40px 20px 25px;
        }

        .section-box .link-more{
            margin-top: 35px;
        }

        .post-home{
            padding-left: 30px;
            padding-right: 30px;
        }
    }

    .news-conatainer-inner{
        padding-top: 40px;
    }

    .content-text {
        .link-more{
            margin-top: 35px;
        }
        .num{
            margin-top: 20px;
            padding-top: 100px;
            padding-bottom: 30px;
        }
    }

    .history-row-1 .content-text{
        padding-bottom: 90px;
    }

    .home-page-map{
        position: relative;
    }

}

@include media-breakpoint-down(lg) {

    .page-home{
        padding-top: 90px;
    }

    .swiper-slider-mian{
        .h2{
            top: 20%;
        }
        .h6{
            position: static;
            padding-top: 30px;
            width: 100%;
        }
    }
    .swiper-map-container{
        .section-box{
            top: 0;
            bottom: 0;
            width: 50%;
        }
    }

    .news-conatainer-inner{
        padding-top: 70px;
    }

    .section-news {
        .post-home{
            padding-left: 10px;
            padding-right: 10px;
        }
    }

    .content-text{
        padding-left: 0;
        padding-right: 0;
    }

    .history-row-2{
        transform: translateY(0px);
    }
}


@include media-breakpoint-down(md) {
    .page-home{
        padding-top: 80px;
    }

    .swiper-slider-mian{
        padding-bottom: 0;
        .slide-image{
            width: 100%;
            padding-bottom: 60%;

            video{
                min-width: auto;
                min-height: auto;
                width: 120%;
            }
        }
        .h2{
            font-size: 64px;
            position: static;
        }
        .slide-btn{
            position: static;
            transform: none;
            margin-top: 40px;
        }
        .slide-content{
            padding: 40px;
            padding-bottom: 20px;
            transform: translateY(-100px);
        }
    }
    
    .section-news{
        .section-news-container{
            display: block;
        }
        .swiper-news-container{
            width: 100%;
        }
    }

    .content-text{
        padding: 20px 40px;
    }

    .history-row-1, .history-row-2{
        padding-right: 40px;
        padding-left: 40px;
    }
    .history-row-2{
        margin-top: 40px;
    }

    .history-row-1{
        .content-text{
            padding-left: 0;
        }
    }
   
}


@include media-breakpoint-down(sm) {

    .swiper-slider-mian .slide-content{
        padding-left: 15px;
        margin-bottom: -90px;
    }
    .section-news{
        .section-box .box-body{
            padding: 40px;
        }

        .section-head{
            margin-bottom: 25px;
        }

        .section-news-container .news-conatainer-inner{
            width: 100%;
            max-width: 100%;
        }

        .post-type-1, .post-type-2, .post-type-3{
            padding-top: 0%;
            .blog-item .image-holder{
                padding-bottom: 90%;
            }
        }

        .promo-post .box-image{
            display: none;
        }
        .section-box .box-body{
            padding: 40px 15px;
        }
        .section-head{
            padding-top: 0;
        }

        .post-home .blog-item .blog-title{
            padding-left: 15px;
        }
        .section-news-text .section-head{
            margin-bottom: 0;
        }
    }

    .swiper-map-container .swiper-plan-nav{
        margin-top: 0;
        padding: 25px 0 20px 0px;
        justify-content: center;
    }

    .history-row-1, .history-row-2{
        padding-left: 15px;
        padding-right: 15px;
    }

    .section-home-content .row{
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .section-home-content{
        .col-img{
            order: 1;
        }
        .col-text{
            order: 2;
        }
    }

    .content-text{
        padding: 20px 15px;
        .num{
            margin-top: 10px;
            padding-top: 40px;
            padding-bottom: 20px;
            &:before{
                height: 35px;
            }
        }
    }
}


