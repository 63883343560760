

[data-aos="translate-to-top"] {
    @include media-breakpoint-up(lg) {
        position: relative;
        z-index: 44;
        opacity: 0;
        transform: translate(0px,60px);
        &.aos-animate {
            opacity: 1;
            transform: translate(0px,0px);
        }
    }
}

[data-aos="translate-to-right"] {
    @include media-breakpoint-up(lg) {
        opacity: 0;
        transform: translate(60px,0px);
        transition: all 0.45s cubic-bezier(0.895, 0.03, 0.685, 0.22);
        &.aos-animate {
            opacity: 1;
            transform: translate(0,0);
        }
    }
}


[data-aos="image-block-to-right"] {
    @include media-breakpoint-up(lg) {
        position: relative;
        overflow: hidden;
        img{
            opacity: 0;
            transform: translateX(-110%);
            transition: all 1.15s;
        }
        &.aos-animate {
            img{
                transform: translateX(0%);    
                opacity: 1;
            }
        }
    }
}

[data-aos="image-block-to-right-sm"] {
    @include media-breakpoint-up(lg) {
        position: relative;
        overflow: hidden;
        img{
            opacity: 0;
            transform: translateX(-110%);
            transition: all 0.65s;
        }
        &.aos-animate {
            img{
                transform: translateX(0%);    
                opacity: 1;
            }
        }
    }
}

[data-aos="image-block-to-top"] {
    @include media-breakpoint-up(lg) {
        position: relative;
        overflow: hidden;
        img{
            opacity: 0;
            transform: translateY(-110%);
            transition: all 0.75s;
        }
        &.aos-animate {
            img{
                transform: translateY(0%);    
                opacity: 1;
            }
        }
    }
}

[data-aos="hero"] {
    @include media-breakpoint-up(lg) {
        .breadcrumb{
            opacity: 0;
            transition: all 0.4s 0.4s;
        }

        .hero-title{
            opacity: 0;
            transform: translateX(-50px);
            transition: all 0.35s 0.45s;
        }
        
        &.aos-animate {
            .breadcrumb{
                opacity: 1;
            }
            .hero-title{
                opacity: 1;
                transform: translateX(0px);
            }
        }
    }
}



[data-aos="post-item"] {
    @include media-breakpoint-up(lg) {
        opacity: 0;
        transform: translateY(100px);
        .blog-meta-container{
            transform: translateY(110%);
            transition: all 0.3s 0.5s;
        }
        &.aos-animate {
            opacity: 1;
            transform: translateY(0px);
            .blog-meta-container{
                transform: translateY(0%);
            }
        }
    }
}

[data-aos="home-content"] {
    @include media-breakpoint-up(lg) {
        .content-text .num{
            opacity: 0;
            transition: all 0.2s 0.2s;
        }
        .content-text .num::before{
            height: 0;
            transition: all 0.25s 0.45s;
        }
        .h4, p{
            opacity: 0;
            transform: translateY(100px);
            transition: all 0.55s 0.65s;
        }
        .link-more{
            opacity: 0;
            transition: all 0.3s 0.65s;
        }
        .image-wrapper{
            position: relative;
            overflow: hidden;
            .image-holder{
                transform: translateY(110%);
                transition: all 0.65s 0.65s;
            }
        }
        
        &.aos-animate {
            .content-text .num{
                opacity: 1;
            }
            .content-text .num::before{
                height: 90px;
            }
            .h4, p{
                opacity: 1;
                transform: translateY(0px);
            }
            .image-wrapper .image-holder{
                transform: translateY(0%);
            }
            .link-more{
                opacity: 1;
            }
        }
    }
}



@include media-breakpoint-up(lg){
    .swiper-main-container{
        .h2, .h6, .slide-btn-wrapper{
            opacity: 0;
            transform: translateX(80px);
            transition: all 0.35s 0.65s;
        }

        .h2{
            transition-delay: 0.75s;
        }
        .h6{
            transition-delay: 1s;
        }
        .slide-btn-wrapper{
            transition-delay: 1.1s;
        }
        .swiper-slide-active{
            .h2, .h6, .slide-btn-wrapper{
                opacity: 1;
                transform: translateY(0px);
            }
        }
    }
}
