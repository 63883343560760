// 
// page: contact
//  

.contact-form{
    margin: 60px 0;
}

.g-map-container{
    .section-box{
        position: absolute;
        z-index: 99;
        top: 0px;
        left: 0%;
        z-index: 10;
        width: 50%;
        left: -33.333%;
    }
}

@include media-breakpoint-down(lg) {
    .g-map-container{
        .section-box{
            position: static;
            z-index: 99;
            width: 100%;
        }
    }
}

@include media-breakpoint-down(md) {
    .contact-form{
        margin: 0;
        padding: 40px;
    }
}

@include media-breakpoint-down(sm) {
    .contact-form{
        padding: 20px;
    }
    .g-map-container{
        .section-box{
            .box-image{
                display: none;
            }
            .box-head{
                margin-bottom: 15px;
            }
            .box-body{
                padding: 40px 20px;
                flex: 1 1 100%;
                text-align: left;
            }
        }
        
    }
    .g-map-data-list{
        margin-top: 20px;
    }
}


