@font-face {
    font-family: 'icomoon';
    src: url('../fonts/icomoon.eot?8jk0pa');
    src: url('../fonts/icomoon.eot?8jk0pa#iefix') format('embedded-opentype'), url('../fonts/icomoon.ttf?8jk0pa') format('truetype'), url('../fonts/icomoon.woff?8jk0pa') format('woff'), url('../fonts/icomoon.svg?8jk0pa#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^="icon-"],
[class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-list:before {
    content: "\e91c";
}

.icon-link:before {
    content: "\e9cb";
}

.icon-attachment:before {
    content: "\e9cd";
}

.icon-google3:before {
    content: "\ea8a";
}

.icon-google-plus:before {
    content: "\ea8b";
}

.icon-facebook1:before {
    content: "\ea90";
}

.icon-facebook2:before {
    content: "\ea91";
}

.icon-instagram1:before {
    content: "\ea92";
}

.icon-whatsapp:before {
    content: "\ea93";
}

.icon-spotify:before {
    content: "\ea94";
}

.icon-telegram:before {
    content: "\ea95";
}

.icon-twitter:before {
    content: "\ea96";
}

.icon-vine:before {
    content: "\ea97";
}

.icon-vk:before {
    content: "\ea98";
}

.icon-renren:before {
    content: "\ea99";
}

.icon-sina-weibo:before {
    content: "\ea9a";
}

.icon-rss:before {
    content: "\ea9b";
}

.icon-rss2:before {
    content: "\ea9c";
}

.icon-youtube:before {
    content: "\ea9d";
}

.icon-youtube2:before {
    content: "\ea9e";
}

.icon-twitch:before {
    content: "\ea9f";
}

.icon-vimeo:before {
    content: "\eaa0";
}

.icon-vimeo2:before {
    content: "\eaa1";
}

.icon-lanyrd:before {
    content: "\eaa2";
}

.icon-flickr:before {
    content: "\eaa3";
}

.icon-flickr2:before {
    content: "\eaa4";
}

.icon-flickr3:before {
    content: "\eaa5";
}

.icon-flickr4:before {
    content: "\eaa6";
}

.icon-dribbble:before {
    content: "\eaa7";
}

.icon-behance:before {
    content: "\eaa8";
}

.icon-linkedin:before {
    content: "\eac9";
}

.icon-linkedin2:before {
    content: "\eaca";
}

.icon-lastfm:before {
    content: "\eacb";
}

.icon-lastfm2:before {
    content: "\eacc";
}

.icon-pinterest:before {
    content: "\ead1";
}

.icon-pinterest2:before {
    content: "\ead2";
}

.icon-arrow-bottom:before {
    content: "\e900";
}

.icon-arrow-left:before {
    content: "\e901";
}

.icon-arrow-right:before {
    content: "\e902";
}

.icon-arrow-top:before {
    content: "\e903";
}

.icon-burger:before {
    content: "\e904";
}

.icon-close-st:before {
    content: "\e905";
}

.icon-cross-out:before {
    content: "\e906";
}

.icon-down-arrow:before {
    content: "\e907";
}

.icon-down:before {
    content: "\e908";
}

.icon-envelope:before {
    content: "\e909";
}

.icon-facebook-logo:before {
    content: "\e90a";
}

.icon-facebook:before {
    content: "\e90b";
}

.icon-file:before {
    content: "\e90c";
}

.icon-instagram-logo:before {
    content: "\e90d";
}

.icon-instagram:before {
    content: "\e90e";
}

.icon-magnifying-glass:before {
    content: "\e90f";
}

.icon-minus:before {
    content: "\e910";
}

.icon-next:before {
    content: "\e911";
}

.icon-phone-receiver:before {
    content: "\e912";
}

.icon-play:before {
    content: "\e913";
}

.icon-plus:before {
    content: "\e914";
}

.icon-prev:before {
    content: "\e915";
}

.icon-resize:before {
    content: "\e916";
}

.icon-tr-down:before {
    content: "\e917";
}

.icon-tr-left:before {
    content: "\e918";
}

.icon-tr-right:before {
    content: "\e919";
}

.icon-tr-up:before {
    content: "\e91a";
}

.icon-up:before {
    content: "\e91b";
}